import {
  AlarmClock,
  Comments,
  DollarSign,
  FireFlameCurved,
  SackDollar,
  Star,
} from '@pelando/fontawesome/icons';
import { isNewSearchLabelsEnabled } from '@/presentation/services/featureFlags';
import { OfferSortOption, ProductSortOptions } from '@/domain/entities/Filter';
import { EventCategory, SearchEvents } from '@/domain/events/analytics';
import { emitTrackingEvent } from '@/infra/analytics/emitTrackingEvent';
import { SearchType } from './types';

export const getIconByOfferSortOption = (option: OfferSortOption) =>
  ({
    [OfferSortOption.CREATED_AT]: AlarmClock,
    [OfferSortOption.TEMPERATURE]: FireFlameCurved,
    [OfferSortOption.COMMENT]: Comments,
    [OfferSortOption.RELEVANCE]: Star,
  }[option]);

export const getIconByProductsSortOption = (option: ProductSortOptions) =>
  ({
    [ProductSortOptions.Recents]: AlarmClock,
    [ProductSortOptions.PriceLowToHigh]: DollarSign,
    [ProductSortOptions.PriceHighToLow]: SackDollar,
  }[option]);

export const getTextBySearchType = (type: SearchType) =>
  ({
    [SearchType.ALL]: 'text-by-search-type-all-key',
    [SearchType.DEALS]: isNewSearchLabelsEnabled()
      ? 'new-text-by-search-type-deals-key'
      : 'text-by-search-type-deals-key',
    [SearchType.PRODUCTS]: isNewSearchLabelsEnabled()
      ? 'new-text-by-search-type-products-key'
      : 'text-by-search-type-products-key',
    [SearchType.STORES]: 'text-by-search-type-stores-key',
  }[type]);

export const getButtonTextByOfferSortOption = (option: OfferSortOption) =>
  ({
    [OfferSortOption.CREATED_AT]: 'text-by-offer-sort-option-created-at-key',
    [OfferSortOption.TEMPERATURE]: 'text-by-offer-sort-option-temperature-key',
    [OfferSortOption.COMMENT]: 'text-by-offer-sort-option-comment-key',
    [OfferSortOption.RELEVANCE]: 'text-by-offer-sort-option-relevance-key',
  }[option]);

export const getButtonTextByProductSortOptions = (option: ProductSortOptions) =>
  ({
    [ProductSortOptions.Recents]: 'text-by-product-sort-options-recents-key',
    [ProductSortOptions.PriceLowToHigh]:
      'text-by-product-sort-options-price-low-to-high-key',
    [ProductSortOptions.PriceHighToLow]:
      'text-by-product-sort-options-price-high-to-low-key',
  }[option]);

export const getClickToSortSearchResultsEventBySortOption = (
  option: OfferSortOption
) =>
  ({
    [OfferSortOption.COMMENT]: () =>
      emitTrackingEvent({
        category: EventCategory.Search,
        name: SearchEvents.ClickToSortSearchResults,
        extra: {
          option: 'last_commented',
        },
      }),
    [OfferSortOption.CREATED_AT]: () =>
      emitTrackingEvent({
        category: EventCategory.Search,
        name: SearchEvents.ClickToSortSearchResults,
        extra: {
          option: 'recent',
        },
      }),
    [OfferSortOption.TEMPERATURE]: () =>
      emitTrackingEvent({
        category: EventCategory.Search,
        name: SearchEvents.ClickToSortSearchResults,
        extra: {
          option: 'hottest',
        },
      }),
    [OfferSortOption.RELEVANCE]: () =>
      emitTrackingEvent({
        category: EventCategory.Search,
        name: SearchEvents.ClickToSortSearchResults,
        extra: {
          option: 'relevance',
        },
      }),
  }[option]);

export const getClickToSelectASearchResultTypeEventBySearchType = (
  type: SearchType
) =>
  ({
    [SearchType.PRODUCTS]: () =>
      emitTrackingEvent({
        category: EventCategory.Search,
        name: SearchEvents.ClickToSelectASearchResultType,
        extra: {
          option: 'products',
        },
      }),
    [SearchType.DEALS]: () =>
      emitTrackingEvent({
        category: EventCategory.Search,
        name: SearchEvents.ClickToSelectASearchResultType,
        extra: {
          option: 'publications',
        },
      }),
    [SearchType.STORES]: () =>
      emitTrackingEvent({
        category: EventCategory.Search,
        name: SearchEvents.ClickToSelectASearchResultType,
        extra: {
          option: 'stores',
        },
      }),
    [SearchType.ALL]: () =>
      emitTrackingEvent({
        category: EventCategory.Search,
        name: SearchEvents.ClickToSelectASearchResultType,
        extra: {
          option: 'all',
        },
      }),
  }[type] || (() => {}));

export const getSearchTypeTestId = (type: SearchType) =>
  `search-filter-${type.toLowerCase()}`;

export const clearSearchTermFromRouteParam = (term: string) =>
  term
    .replace(/-/gi, ' ') // deslugfy
    .replace(/['"]+/g, '') // fix quotations
    .trim();

export const getSearchTermsWithBrandFiltersSuggestions = () => [
  'celular',
  'celulares',
  'smartphones',
  'geladeira',
  'refrigerador',
  'tv',
  'televisao',
  'smart tv',
  'ar condicionado',
  'notebook',
  'notebook gamer',
];

export const getSearchTermsWithCapacityFiltersSuggestions = () => [
  'celular',
  'celulares',
  'smartphones',
  'tv',
  'televisao',
  'smart tv',
  'notebook',
  'notebook gamer',
];

export const getBrandFilterField = () => 'techspecs.Marca';

export const getScreenSizeFilterField = () => 'techspecs.Tamanho da Tela';

export const getCapacityFilterField = () => 'techspecs.Capacidade';

export const normalizeSearchTerm = (searchTerm: string) =>
  searchTerm.trim().toLowerCase();

export const searchTermMatchesBrandFilterSuggestions = (searchTerm: string) => {
  const normalizedSearchTerm = normalizeSearchTerm(searchTerm);

  return getSearchTermsWithBrandFiltersSuggestions().includes(
    normalizedSearchTerm
  );
};

export const searchTermMatchesCapacityFilterSuggestions = (
  searchTerm: string
) => {
  const normalizedSearchTerm = normalizeSearchTerm(searchTerm);

  return getSearchTermsWithCapacityFiltersSuggestions().includes(
    normalizedSearchTerm
  );
};
