'use client';

/* eslint-disable camelcase */
import { useRouter } from 'next/router';
import { PropsWithChildren, useEffect, useRef } from 'react';
import { useMediaQuery, MediaQuery } from '@pelando/components';
import dynamic from 'next/dynamic';
import env from '@/config/environment';
import ALERT_BANNER_DESKTOP from 'public/assets/pt-br/login/login-alert-banner.png';
import ALERT_BANNER_DESKTOP_MOBILE from 'public/assets/pt-br/login/login-alert-banner-mobile.png';
import BANNER_DESKTOP_NINJA from 'public/assets/en-us/login/login-banner.png';
import BANNER_MOBILE_NINJA from 'public/assets/en-us/login/login-banner-mobile.png';
import {
  AuthKind,
  LoginComponentSourceName,
} from '../components/Authentication/types';
import { useAuthModal } from '../components/Authentication/hooks/useAuthModal';
import SEO from '../components/SEO';
import { useTranslation } from '../hooks/useTranslation';
import { useCurrentUserCache } from '../hooks/useCurrentUserCache';
import { getProbablyLoggedCookie } from '../../infra/auth/tokenManagement';
import { BannerImage } from '../components/Authentication/hooks/useAuthModal/styles';

export const protectedRoutes = [
  '/meu-perfil',
  '/configuracoes',
  '/meus-alertas',
  '/atendimento',
  '/promocoes-salvas',
  '/postar',
  '/settings',
  '/my-alerts',
  '/service',
  '/saved-promos',
  '/post',
  '/my-profile',
];

const AuthModal = dynamic(
  () =>
    import('@/presentation/components/Authentication').then(
      (mod) => mod.default
    ),
  { ssr: false }
);

export const useIsProtectedRoute = () => {
  const { asPath, query } = useRouter();
  const { user_id, notif_pref_token } = query;
  if (asPath.includes('/configuracoes') && user_id && notif_pref_token)
    return false;
  return protectedRoutes.some((protectedRoute) =>
    asPath.includes(protectedRoute)
  );
};

type RouteGuardProps = PropsWithChildren<unknown>;

const RouteGuard = ({ children }: RouteGuardProps) => {
  const { t } = useTranslation('login');
  const { push, asPath, locale } = useRouter();
  const { showModal, closeModal } = useAuthModal();
  const isMobile = useMediaQuery(MediaQuery.SCREEN_SM);
  const isProtected = useIsProtectedRoute();
  const { isLogged } = useCurrentUserCache();
  const canAccessRoute = isProtected ? isLogged : true;
  const persistRef = useRef(false);

  const isAlertPage =
    locale === 'pt-BR'
      ? asPath.includes('/meus-alertas')
      : asPath.includes('/my-alerts');
  const modalTitle = isAlertPage ? 'auth-alert-message' : 'login-title-modal';
  const modalSubtitle = isAlertPage
    ? 'auth-alert-subtitle-message'
    : 'auth-modal-subtitle';
  const modalComponentSourceName = isAlertPage
    ? LoginComponentSourceName.modalAlert
    : LoginComponentSourceName.authModal;

  useEffect(() => {
    persistRef.current = canAccessRoute;
  }, [canAccessRoute]);

  const handleCloseModal = () => {
    if (persistRef.current) return;
    persistRef.current = false;
    closeModal();
    push('/');
  };

  useEffect(() => {
    if (canAccessRoute || getProbablyLoggedCookie()) {
      closeModal();
      return;
    }

    const ALERT_BANNER_MOBILE_LOCALE =
      locale === 'en-US' ? BANNER_MOBILE_NINJA : ALERT_BANNER_DESKTOP_MOBILE;

    const ALERT_BANNER_DESKTOP_LOCALE =
      locale === 'en-US' ? BANNER_DESKTOP_NINJA : ALERT_BANNER_DESKTOP;

    const bannerImage = isMobile
      ? ALERT_BANNER_MOBILE_LOCALE
      : ALERT_BANNER_DESKTOP_LOCALE;

    showModal(
      <AuthModal
        kind={AuthKind.LOGIN}
        modalTitle={t(modalTitle)}
        modalSubtitle={t(modalSubtitle)}
        componentSourceName={modalComponentSourceName}
        onFinishSuccessfully={() => {
          persistRef.current = true;
        }}
        onCloseModal={handleCloseModal}
        isLogged
        googleClientId={env.AUTH_GOOGLE_APP_ID}
      />,
      {
        onClose: handleCloseModal,
        bannerImage: isAlertPage && (
          <BannerImage
            src={bannerImage}
            alt={t('auth-alert-banner-alt-text')}
          />
        ),
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canAccessRoute, showModal]);

  if (canAccessRoute) {
    return <>{children}</>;
  }

  return <SEO robots="noindex, nofollow" />;
};

export default RouteGuard;
