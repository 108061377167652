export const ALL_STORES_URLS = {
  'pt-BR': '/cupons-de-descontos',
  'en-US': '/promo-codes',
};

export const LOGIN = {
  'pt-BR': '/entrar',
  'en-US': '/login',
};

export const SIGN_UP = {
  'pt-BR': '/cadastrar',
  'en-US': '/signup',
};

export const ALERTS = {
  'pt-BR': '/meus-alertas',
  'en-US': '/my-alerts',
};

export const PROMOS = {
  'pt-BR': '/promocoes-salvas',
  'en-US': '/saved-promos',
};

export const SETTINGS = {
  'pt-BR': '/configuracoes',
  'en-US': '/settings',
};

export const CONTACT = {
  'pt-BR': '/atendimento',
  'en-US': '/service',
};

export const CATEGORIES = {
  'pt-BR': '#categorias',
  'en-US': '#categories',
};

export const COMFIRM_EMAIL = {
  'pt-BR': '/confirmar-email',
  'en-US': '/confirm-email',
};

export const COMPARATOR = {
  'pt-BR': '/comparador',
  'en-US': '/comparator',
};

export const POST = {
  'pt-BR': '/postar',
  'en-US': '/post',
};

export const EDIT_OFFER = {
  'pt-BR': '/editar-oferta',
  'en-US': '/edit-offer',
};

export const ABOUT = {
  'pt-BR': '/sobre',
  'en-US': '/about',
};

export const PRIVACY_POLICY = {
  'pt-BR': '/politica-de-privacidade',
  'en-US': '/privacy-policy',
};

export const COOKIES_POLICY = {
  'pt-BR': '/politica-de-cookies',
  'en-US': '/cookies-policy',
};

export const CONDUCT_CODE = {
  'pt-BR': '/codigo-de-conduta',
  'en-US': '/code-of-conduct',
};

export const TERMS = {
  'pt-BR': '/termos-de-uso',
  'en-US': '/terms-of-use',
};

export const SEARCH = {
  'pt-BR': '/busca',
  'en-US': '/s',
};

export const PROFILE = {
  'pt-BR': '/perfil',
  'en-US': '/profile',
};

export const MY_PROFILE = {
  'pt-BR': '/meu-perfil',
  'en-US': '/my-profile',
};

export const FOLLOWERS = {
  'pt-BR': '/seguidores',
  'en-US': '/followers',
};

export const FOLLOWING = {
  'pt-BR': 'seguindo',
  'en-US': 'following',
};
